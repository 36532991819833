import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '费用报销单号', prop: 'reim_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '报销人',
    prop: 'cust_stff_bname',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '报销日期',
    prop: 'reim_reidate',
    itemType: 'date',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '申请人',
    prop: 'cust_stff_aname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '申请日期',
    prop: 'reim_appdate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '公司名称', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '费用科目',
    prop: 'reimFeesListStr',
    itemType: 'select',
    options: [],
    input: false,
    sortable: false,
    widthAuto: true
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    input: false,
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
export const syncDialogTableProperties = [
  { label: '单据号', prop: 'reim_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '报销人',
    prop: 'cust_stff_bname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '申请人',
    prop: 'cust_stff_aname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  { label: '公司名称', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '总金额', prop: 'reim_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
